import React, {memo, useRef, useState, useEffect} from "react"
import {Link, navigate} from "gatsby"
import {motion, useAnimation} from "framer-motion"
import 'intersection-observer'
import {useInView} from "react-intersection-observer"
import Img from "gatsby-image"


const hoverTransition = {
  type: 'spring',
  damping: 14,
  mass: 1,
  stiffness: 150,
  restDelta: 0
}


const ProjectBlock = (props) => {

  const [isAnimating, setIsAnimating] = useState(false)
  const containerControls = useAnimation()
  const bkgControls = useAnimation()
  const imageContainerRef = useRef()
  const projectClasses = useRef('project')
  const preventRunning = useRef(true)
  const waitDuration = props.oldRoute ? 1400 : 2000
  const {width: vw, height: vh} = props.windowSize
  const {
    slug,
    hero,
    title,
    year,
    mainColor,
  } = props.node


  // FIXME: Disable intersection observer at the start of the animation.
  const [projectRef, inView] = useInView({
    threshold: .75,
  })

  const bkgHover = () => {
    bkgControls.start({
      scale: .99,
      transition: hoverTransition
    })
  }
  const bkgHoverOff = () => {
    bkgControls.start({
      scale: 1,
      transition: {
        type: 'spring',
        damping: 28,
        mass: 1.6,
        stiffness: 150,
        restDelta: 0
      }
    })
  }

  const windUp = (duration) => {
    bkgControls.start({
      scale: .97,
      transition: {
        type: 'tween',
        ease: 'easeOut',
        duration: duration
      }
    })
  }

  // Due to parent transform, prevent running until animation is finished
  // Better to feel like a bad click/tap (user error) than a bug
  useEffect(() => {
    preventRunning.current = Date.now()
    setTimeout(() => {
      preventRunning.current = false
    }, waitDuration)
  }, [])

  // Running on state change rather than directly to ensure state is set
  useEffect(() => {
    if (isAnimating) toStudy()
  }, [isAnimating])

  const toStudy = () => {

    // Get values. Takes transforms into account...
    let {top, left, width, height} = imageContainerRef.current.getBoundingClientRect()

    // Set fixed in same position
    containerControls.set({
      position: 'fixed',
      left: 0,
      top: 0,
      x: left,
      y: top,
      zIndex: 999
    })
    
    // Animate to fill screen
    bkgControls.start({
      y: (vh / 2) - (height / 2) - top, // center and remove "top" adjust
      scaleX: (vw / width) + .02,
      scaleY: (vh / height) + .04,
      transition: {
        scaleX: { // Faster
          type: 'spring',
          damping: 20,
          mass: vw > 1025 ? .5 : 8.5,
          stiffness: 200,
          restDelta: .01,
          // velocity: vw > 1025 ? 0 : -.1
        },
        default: { // Timed to end right at the page transition. Don't change
          type: 'spring',
          damping: 28,
          mass: 1.6,
          stiffness: 150,
          restDelta: .01
        }
      }
    })
  }

  // Update classes if not currently animating
  if (props.shouldUpdateClasses.current) {
    projectClasses.current = "project" + (isAnimating ? " -animating" : "") + (inView ? " -visible" : "")
    if (isAnimating) {
      props.shouldUpdateClasses.current = false
    }
  }
  
  // console.log("Project Block")

  return (
    <Link
      onClick={(e)=>{
        if (preventRunning.current) {
          e.preventDefault()
          e.stopPropagation()

          // Get time left in timeout, minimum value of 100ms
          let timeRemaining = Math.max(100, (waitDuration - (Date.now() - preventRunning.current)))
          windUp(timeRemaining / 1000)

          setTimeout(() => {
            bkgHoverOff()
            setIsAnimating(true)
            navigate(`/case-study/${slug}`)
          }, timeRemaining)
        } else {
          bkgHoverOff()
          setIsAnimating(true)
        }
      }}
      onMouseEnter={() => {!isAnimating && bkgHover()}}
      onMouseLeave={() => {!isAnimating && bkgHoverOff()}}
      to={`/case-study/${slug}`}
      className={projectClasses.current}
      ref={projectRef}
      id={`${slug}`}
    >
      <motion.div
        className="project__filter-animation"
        initial={"hidden"}
        animate={props.filterResponseControls}
      >
        <motion.div
          key={`/case-study/${slug}`}
          className="project__container"
          ref={imageContainerRef}
          initial={false}
          animate={containerControls}
        >
          <motion.div
            className="project__bkg"
            animate={bkgControls}
            style={{backgroundColor: mainColor}}
          />
          <div className="project__image-wrapper">
            <Img className="project__image" imgStyle={{objectFit: "contain"}} fluid={hero.fluid} />
          </div>
          <h2 className="project__title">{title}</h2>
          <span className="project__date">{year}</span>
          <span className="project__type">UX</span>
          <svg className="project__arrow" width="24" height="17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.3 1L22 8.5 14.3 16M22 8.5H0" stroke="#f7f7f7" strokeWidth="2"/></svg>
        </motion.div>
      </motion.div>
    </Link>
  )
}

export default memo(ProjectBlock)
