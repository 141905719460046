import React, {useEffect, useMemo, useRef, useContext} from "react"
import ProjectBlock from "./project-block"
import Snack from "./snack"
import Filters from "./filters"
import {useStaticQuery, graphql} from 'gatsby'
import {motion, useAnimation} from "framer-motion"
import Loader from '../global/loader'
import "./project-list.scss"
import {useWindowSize} from '../helpers/util'
import StateContext from '../global/StateContext'


const ProjectList = (props) => {
	const filterResponseControls = useAnimation()
	const loaderControls = useAnimation()
	const loaderLineControls = useAnimation()
	const windowSize = useWindowSize()
	const mounted = useRef(false)
	const shouldUpdateClasses = useRef(true)
	const context = useContext(StateContext)

	const data = useStaticQuery(graphql`
		query listQuery {
			contentfulCaseStudyList {
				projects {
					title
					slug
					year
					hero {
						fluid {
							...GatsbyContentfulFluid_withWebp_noBase64
						}
					}
					tags,
					mainColor
				}
			}
			contentfulSnacks {
				snackList {
					file {
						url
					}
					fluid {
						...GatsbyContentfulFluid_withWebp_noBase64
					}
				}
			}
		}
	`)
		const allProjects = data.contentfulCaseStudyList.projects
		const snacks = data.contentfulSnacks.snackList

		useEffect(() => {
			if (!mounted.current) {
				mounted.current = true
			} else {
				// Set initials
				filterResponseControls.set({
					opacity: 0,
					y: 60
				})
				loaderControls.set("initial")
				loaderLineControls.set("initial")
	
				// Animate
				loaderLineControls.start("animate")
				loaderControls.start("animate")
				filterResponseControls.start({
					y: 0,
					opacity: 1,
					transition: {
						type: 'spring',
						damping: 28,
						mass: 1,
						stiffness: 170,
						delay: .35
					}
				})
			}
		}, [context.filter])

		const filterProjects = (value) => {
			return value ? allProjects.filter((project) => project.tags.some((tag) => tag === value)) : allProjects
		}

		const tagCounts = useMemo(() => {
			let allTags = []
			for (let study of allProjects) {
				if (study.tags) {
					allTags.push(...study.tags)
				}
			}
			for (let snack of snacks) {
				allTags.push('Snacks')
			}
	
			var counts = {}
			allTags.forEach((x) => { counts[x] = (counts[x] || 0)+1; })

			return counts
		}, [allProjects, snacks])

		return (
			<div className="projects" id="projects">
				<Loader loaderControls={loaderControls} loaderLineControls={loaderLineControls}/>
				<Filters tags={tagCounts} filter={context.filter} setFilter={context.setFilter} filterResponseControls={filterResponseControls}/>
				{filterProjects(context.filter).map((project, i) => <ProjectBlock node={project} key={i} oldRoute={props.oldRoute} shouldUpdateClasses={shouldUpdateClasses} filterResponseControls={filterResponseControls} windowSize={windowSize}/>)}
				{context.filter === 'Snacks' && <motion.div className="snacks" animate={filterResponseControls}>
					{snacks.map((snack, i) => <Snack node={snack} key={i} filterResponseControls={filterResponseControls} windowWidth={windowSize.width}/> )}
				</motion.div>}
			</div>
		)

}

export default ProjectList