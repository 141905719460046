import React, { useEffect } from "react"
import 'intersection-observer'
import {useInView} from "react-intersection-observer"
import Img from "gatsby-image"

export default (props) => {
  const [snackRef, inView, entry] = useInView({
    /* Optional options */
		threshold: .75,
		rootMargin: "-15% 0px -15% 0px"
	})
	const snack = props.node

	const hoverPlay = (e) => {
		props.windowWidth > 1024 && e.target.play()
	}

	const hoverPause = (e) => {
		props.windowWidth > 1024 && e.target.pause()
	}

	useEffect(() => {
		if (props.windowWidth <= 1024 && entry) {
			inView ? entry.target.play() : entry.target.pause()
		}
	}, [inView])

  return (
		<div className="snacks__block">
			{snack.fluid ? (
				<Img
				style={{ // Set up to work the same as the videos w/ the padding bottom aspect ratio of container
					position: 'absolute',
					left: 0,
					top: 0,
					width: '100%',
					height: '100%'
				}}
				fluid={snack.fluid}
				/>
				) : (
				<video
					playsInline
					preload="metadata"
					loop
					muted
					onMouseOver={(e) => hoverPlay(e)}
					onMouseOut={(e) => hoverPause(e)}
					ref={snackRef}
				>
					<source src={snack.file.url + "#t=0.1"} />
					Your browser does not support video.
				</video>
			)}
		</div>
  )
}
