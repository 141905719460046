import React from "react"
import './filters.scss'

export default (props) => {

	return (
		<ul className="filters">
			{Object.entries(props.tags).map((tag, i) => 
				<li className={props.filter === tag[0] ? "filters__item --active" : "filters__item"} key={i}>
					<a
						role="button"
						tabIndex="0"
						onClick={()=> {
							props.setFilter(tag[0])
						}}
						onKeyPress={(e)=> {
							// #accessibilitybrooo
							if(e.key === "Enter"){props.setFilter(tag[0])}
						}}
					>{tag[0]}</a>
					<span>{tag[1]}</span>
				</li>
			)}
		</ul>
	)
}