import React, { memo } from "react"
import {useStaticQuery, graphql} from 'gatsby'
import {motion} from 'framer-motion'
import SEO from "../components/global/seo"
import Hero from "../components/hero/hero"
import ProjectList from "../components/project-list/project-list"
import Footer from '../components/global/footer'
import {homeVariants} from "../components/global/variants"


const Home = (props) => {

  const data = useStaticQuery(graphql`
    query siteQuery {
      site {
        buildTime
      }
    }
  `)
  const date = data.site.buildTime.substr(0, 10).split("-")

  // console.log('Home')
  return (
    <motion.main
    key={props.location.pathname}
    className="motion-page home"
    initial="initial"
    animate="animate"
    exit="exit"
    custom={props.oldRoute}
    variants={homeVariants}
  >
    <SEO title="Home" />
    <Hero
      headline="Work"
      leftText={date[1] + " . " + date[2] + " . " + date[0]}
      rightText="v2.31"
      oldRoute={props.oldRoute}
    />
    <ProjectList oldRoute={props.oldRoute}/>
    <Footer/>
  </motion.main>
  )
}

export default memo(Home)